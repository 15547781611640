<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="inherit"
      d="M16.36,14 C16.44,13.34 16.5,12.68 16.5,12 C16.5,11.32 16.44,10.66 16.36,10 L19.74,10 C19.9,10.64 20,11.31 20,12 C20,12.69 19.9,13.36 19.74,14 M14.59,19.56 C15.19,18.45 15.65,17.25 15.97,16 L18.92,16 C17.96,17.65 16.43,18.93 14.59,19.56 M14.34,14 L9.66,14 C9.56,13.34 9.5,12.68 9.5,12 C9.5,11.32 9.56,10.65 9.66,10 L14.34,10 C14.43,10.65 14.5,11.32 14.5,12 C14.5,12.68 14.43,13.34 14.34,14 M12,19.96 C11.17,18.76 10.5,17.43 10.09,16 L13.91,16 C13.5,17.43 12.83,18.76 12,19.96 M8,8 L5.08,8 C6.03,6.34 7.57,5.06 9.4,4.44 C8.8,5.55 8.35,6.75 8,8 M5.08,16 L8,16 C8.35,17.25 8.8,18.45 9.4,19.56 C7.57,18.93 6.03,17.65 5.08,16 M4.26,14 C4.1,13.36 4,12.69 4,12 C4,11.31 4.1,10.64 4.26,10 L7.64,10 C7.56,10.66 7.5,11.32 7.5,12 C7.5,12.68 7.56,13.34 7.64,14 M12,4.03 C12.83,5.23 13.5,6.57 13.91,8 L10.09,8 C10.5,6.57 11.17,5.23 12,4.03 M18.92,8 L15.97,8 C15.65,6.75 15.19,5.55 14.59,4.44 C16.43,5.07 17.96,6.34 18.92,8 M12,2 C6.47,2 2,6.5 2,12 C2,14.6521649 3.0535684,17.195704 4.92893219,19.0710678 C6.80429597,20.9464316 9.3478351,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z"
    />
  </svg>
</template>
<script>
import { LANGUAGE_ICON_BASE } from '@/constants'

export default {
  name: LANGUAGE_ICON_BASE,
}
</script>
